import { EmailSendStatus } from "@/constants/email-send-status";
import { TRootState } from "@/store/app/store";
import { resetAccountState } from "@/store/features/account/accountSlice";
import { resetCarrierReportState } from "@/store/features/carrier-report/carrierReportSlice";
import {
  resetEmailState,
  setEmailStatus,
} from "@/store/features/email/emailSlice";
import { resetLoadInfoState } from "@/store/features/load-info/loadInfoSlice";
import { resetTabsState } from "@/store/features/tabs/tabsSlice";
import { resetTripInfoState } from "@/store/features/trip-info/tripInfoSlice";
import { ThunkDispatch } from "@reduxjs/toolkit";

type ResetStoreFunc = (
  dispatch: ThunkDispatch<TRootState, any, any>,
  needToResetTokens?: boolean
) => void;

export const resetStore: ResetStoreFunc = (
  dispatch,
  needToResetTokens = false
) => {
  if (needToResetTokens) {
    dispatch(resetAccountState());
    dispatch(resetEmailState());
  }
  dispatch(setEmailStatus(EmailSendStatus.NONE));
  dispatch(resetLoadInfoState());
  dispatch(resetTripInfoState());
  dispatch(resetTabsState());
  dispatch(resetCarrierReportState());
};
