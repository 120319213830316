import { useCallback, useEffect, useMemo, useState } from "react";
import { InputField } from "./InputField";
import { useAppDispatch, useAppSelector } from "@/store/app/hooks";
import { loadDataSelector } from "@/store/features/load-info/loadInfoSelectors";
import {
  tripDataLoadingSelector,
  tripDataSelector,
} from "@/store/features/trip-info/tripInfoSelectors";
import { Loader } from "../loader";
import { isRateEmpty } from "@/utils/isRateEmpty";
import { sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";
import { Switch } from "@nextui-org/react";
import { setActiveUnitSystem } from "@/store/features/trip-info/tripInfoSlice";

export const CalculatorView = () => {
  const dispatch = useAppDispatch();
  const loadInfo = useAppSelector(loadDataSelector);
  const tripInfo = useAppSelector(tripDataSelector);
  const loading = useAppSelector(tripDataLoadingSelector);

  const {
    distance,
    tollCost,
    fuelPrice,
    activeUnitSystem,
    isUnitsSwitchAccessible,
  } = tripInfo || {};

  const [miles, setMiles] = useState(0);
  const [rpm, setRpm] = useState(0);
  const [mpg, setMpg] = useState(6.8);
  const [diesel, setDiesel] = useState(0);
  const [toll, setToll] = useState(0);
  const [rate, setRate] = useState(0);
  const [fuelCost, setFuelCost] = useState(0);
  const [spendings, setSpendings] = useState(0);

  const currencySign = useMemo(
    () => (activeUnitSystem === "imperial" ? "$" : "C$"),
    [activeUnitSystem]
  );

  const distanceTerm = useMemo(
    () => (activeUnitSystem === "imperial" ? "mi" : "km"),
    [activeUnitSystem]
  );

  const MPGTerm = useMemo(
    () => (activeUnitSystem === "imperial" ? "MPG" : "KPL"),
    [activeUnitSystem]
  );

  const setMilesIn = useCallback(
    (val: number) => {
      setMiles(val);

      setRpm(Number((rate / val).toFixed(2)));
      setFuelCost(Number(((val / mpg) * diesel).toFixed(2)));
    },
    [diesel, mpg, rate]
  );

  const setRpmIn = useCallback(
    (val: number) => {
      setRpm(val);
      setRate(Number((val * miles).toFixed(2)));
      setFuelCost(Number(((miles / mpg) * diesel).toFixed(2)));
    },
    [diesel, miles, mpg]
  );

  const setMpgIn = useCallback(
    (val: number) => {
      setMpg(val);
      setFuelCost(Number(((miles / val) * diesel).toFixed(2)));
    },
    [diesel, miles]
  );

  const setRateIn = useCallback(
    (val: number) => {
      setRate(val);

      setRpm(Number((val / miles).toFixed(2)));
      setFuelCost(Number(((miles / mpg) * diesel).toFixed(2)));
    },
    [diesel, miles, mpg]
  );

  const setFuelCostTollsIn = useCallback((val: number) => {
    setFuelCost(val);
  }, []);

  const handleUnitChange = useCallback(
    (value: boolean) => {
      if (value) {
        setMilesIn(distance?.kilometersRaw || 0);
        dispatch(setActiveUnitSystem("metric"));
      } else {
        setMilesIn(distance?.milesRaw || 0);
        dispatch(setActiveUnitSystem("imperial"));
      }
    },
    [dispatch, distance?.kilometersRaw, distance?.milesRaw, setMilesIn]
  );

  useEffect(() => {
    const actveDieselValue =
      activeUnitSystem === "imperial" ? fuelPrice?.gallon : fuelPrice?.liter;
    if (actveDieselValue) {
      const die = Number(actveDieselValue);
      setDiesel(die);
      setFuelCost(Number(((miles / mpg) * die).toFixed(2)));
    }
    if (tollCost.usd) {
      setToll(
        Number(activeUnitSystem === "imperial" ? tollCost.usd : tollCost.cad)
      );
    }
  }, [
    activeUnitSystem,
    fuelPrice?.gallon,
    fuelPrice?.liter,
    miles,
    mpg,
    tollCost.cad,
    tollCost.usd,
    tripInfo,
  ]);

  useEffect(() => {
    if (!loadInfo || !tripInfo) return;
    let milesIn = 0;

    if (distance) {
      const trip =
        activeUnitSystem === "imperial"
          ? distance.milesRaw
          : distance.kilometersRaw;
      milesIn = Number(trip);
      setMilesIn(milesIn);
    }
    let rateIn = 0;
    if (!isRateEmpty(loadInfo?.rate)) {
      rateIn = Number(loadInfo?.rate.replace("$", "").replace(",", ""));
      setRateIn(rateIn);
    } else {
      setRateIn(0);
    }

    setMilesIn(milesIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadInfo, distance]);

  const handleMilesFocus = () =>
    sendAmplitudeData(AMPLITUDE_EVENTS.popupMilesValueChanged);
  const handleRpmCalculatorFocus = () =>
    sendAmplitudeData(AMPLITUDE_EVENTS.popupRpmCalculatorValueChanged);
  const handleMpgFocus = () =>
    sendAmplitudeData(AMPLITUDE_EVENTS.popupMpgValueChanged);
  const handleRateFocus = () =>
    sendAmplitudeData(AMPLITUDE_EVENTS.popupRateValueChanged);
  const handleFuelCostFocus = () =>
    sendAmplitudeData(AMPLITUDE_EVENTS.popupFuelCostValueChanged);

  if (!tripInfo || !loadInfo) return <div></div>;

  return (
    <div className="relative flex flex-col justify-between bg-brand-blue-700 text-white">
      <div className="h-full flex flex-col gap-2 p-3">
        <div className="flex w-full justify-between">
          <h2 className="font-semibold text-xl">Calculator</h2>
          {isUnitsSwitchAccessible && (
            <div className="flex gap-1 items-center">
              <small>mi</small>
              <Switch
                size="sm"
                isSelected={activeUnitSystem === "metric"}
                onValueChange={handleUnitChange}
                classNames={{
                  wrapper: "mr-0 !bg-brand-700",
                }}
              />
              <small>km</small>
            </div>
          )}
        </div>
        <div className="h-full flex flex-col gap-2">
          <div className="w-full grid grid-cols-3 gap-2">
            <InputField
              label={`Trip - ${distanceTerm}`}
              min={0}
              value={miles.toString()}
              onValueChange={setMilesIn}
              onFocus={handleMilesFocus}
            />
            <InputField
              label={`RPM - ${currencySign}`}
              step={0.01}
              min={0}
              value={rpm.toString()}
              onValueChange={setRpmIn}
              onFocus={handleRpmCalculatorFocus}
            />
            <InputField
              label={MPGTerm}
              step={0.01}
              min={0.01}
              value={mpg.toString()}
              onValueChange={setMpgIn}
              onFocus={handleMpgFocus}
            />
            <InputField
              label={`Rate - ${currencySign}`}
              min={0}
              step={10}
              value={rate.toString()}
              onValueChange={setRateIn}
              onFocus={handleRateFocus}
            />
            <InputField
              label={`Fuel - ${currencySign}`}
              min={0}
              step={0.01}
              value={fuelCost.toString()}
              onValueChange={setFuelCostTollsIn}
              onFocus={handleFuelCostFocus}
            />
            <InputField
              label={`Spends - ${currencySign}`}
              min={0}
              step={0.01}
              value={spendings.toString()}
              onValueChange={setSpendings}
            />
          </div>
        </div>
      </div>
      <div className="h-[50px] shrink-0 px-4 py-5 flex justify-between items-center bg-brand-700">
        <h2 className="font-normal text-md">Profit</h2>
        <p className="font-semibold text-md">
          {currencySign} {(rate - fuelCost - toll - spendings).toFixed(2)}
        </p>
      </div>
      <Loader dark show={loading} />
    </div>
  );
};
